import Vue from 'vue'
import App from './App.vue'
import axios from 'axios';
import router from '@/router'  
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/style/base.css';
import {httpAjax} from '@/utils/api.js'

Vue.prototype.$ajax = httpAjax
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.use(ElementUI);

router.beforeEach((to,from,next) => {
  if (!localStorage.getItem('token')&& to.path !== '/login') {
    next('/login');
  } else {
    next()
  }
})

axios.interceptors.response.use(response =>{
  if (response.data.state == 401) {
    router.push('/login')
  } else {
    // 对响应数据进行处理
    return response
  }
  
  }, error =>{
  // 对响应错误进行处理
  return Promise.reject(error)
  })

new Vue({
  router,
  render: h => h(App)
}).$mount("#app")
