import qs from 'qs';
import axios from 'axios'
import router from '@/router'  
// let baseUrl = 'http://192.168.1.7:9003/v3'
let baseUrl = 'https://finance.qingfeifeicui.cn/v3'

// let baseUrl = 'http://192.168.1.5:9003'


export const httpAjax = (method, url, params,load) => {
    let header ={}
    if (method == 'post') {
        header['Content-Type'] = 'application/json;charset=utf-8'
    } else {
        header['Content-Type'] = 'application/x-www-form-urlencoded'
    }
    if (localStorage.getItem("token")) {
        header['token'] = localStorage.getItem("token")
    }
    
    let data,urlAll
    if (method == 'post') {
        data = qs.parse(params)
        urlAll = `${baseUrl}` + url
    } else {
        data = qs.stringify(params)
        urlAll = `${baseUrl}` + url +'?' + data
    }
    if (load) {
        return new Promise((resolve, reject) => {
            axios({
                method: method ? method : 'post',
                url: urlAll,
                data: params,
                headers:{
                    "Content-Type": "application/json",
                    'token': localStorage.getItem("token")
                },
                responseType: 'blob'
            }).then(res => {
                resolve(res.data)
                
            }).catch(err =>{ console.log('err--',err)
                reject(err)})
        })
    } else {
        return new Promise((resolve, reject) => {
            axios({
                method: method ? method : 'post',
                url: urlAll,
                data: params,
                headers:header,
            }).then(res => {
                if (res&&res.data) {
                    if (res.data.state == 401) {
                        router.push('/login')
                    } else {
                        resolve(res.data);
                    }
                }
                
            }).catch(err =>{ console.log('err--',err)
                reject(err)})
        })
    }
}
// export default httpRequest