import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

export default new Router({
  // mode: "history",
  routes: [
    {
      path: '/',
      component: () => import('@/components/common/Base.vue'),
      redirect: '/list',
      meta: {
        title: '公共部分'
      },
      children: [
        {
          path: '/list',
          component: () => import('@/views/sell/list.vue'),
          meta: {
            title: '销售日报'
          }
        },
      ]
    },
    {
      path: '/login',
      component: () => import('@/views/login.vue')
    },
    // {
    //   path: '/error',
    //   component: () => import('../components/page/Error.vue')
    // },
    // {
    //   path: '/404',
    //   component: () => import('../components/page/404.vue')
    // }
  ]
})
