<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>

export default {
  name: 'App',
  provide(){
    return {
      reload:this.reload
    }
  },
  data(){
    return {
      isRouterAlive: true
    }
  },
  methods:{
    reload(){
      this.isRouterAlive = false
      this.$nextTick(()=>{
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style lang="less">
#app {
  font-family: Helvetica, Arial, sans-serif,Avenir ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  height: 100vh;
  margin: 0;
  padding: 0;
}
body,ul,li{
  margin: 0;
  padding: 0;
}
/deep/ .el-button--primary {
  background-color: #2a64c5!important;
  border-color: #2a64c5!important;
}
/deep/ .el-button--text {
  color: #2a64c5!important;
}
/deep/ .el-message {
  min-width: 300px!important;
  width: 300px;
}
</style>
